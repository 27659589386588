import React from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput, BooleanInput, Labeled } from 'react-admin';

import Edit, { EditActions } from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';
import DateField from '../common/fields/DateField';
import ProjectInput from '../projects/inputs/ProjectInput';
import HelpButton from './buttons/HelpButton';

const optionText = choice => choice?.name ? choice.name : '';

const TimeEntryActions = () => (
    <EditActions>
        <HelpButton />
    </EditActions>
);

const TimeEntryEdit = (props) => (
    <Edit {...props} actions={<TimeEntryActions />}>
        <SimpleForm>
            <Labeled sx={{ marginBottom: '30px' }}>
                <DateField source="created_at" showTime />
            </Labeled>
            <TextInput source="description" multiline fullWidth rows="4" />
            <DateInput source="date" />
            <ProjectInput />
            <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
                <AutocompleteInput optionText={optionText} size="small" />
            </ReferenceInput>
            <NumberInput source="hours" step={0.25} min={0.25} />
            <BooleanInput source="invoiced" />
            <TextInput source="notes" multiline fullWidth rows="4" />
        </SimpleForm>
    </Edit>
);

export default TimeEntryEdit;
