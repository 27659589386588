import React from 'react';
import { RadioButtonGroupInput, FieldTitle } from 'react-admin';
import { FormLabel, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

// TODO: fix theme later
const useStyles = makeStyles(theme => ({
    label: {
        marginLeft: '12px',
        color: theme?.palette?.text?.primary,
        '& span': {
            whiteSpace: 'nowrap'
        }
    },
	switch: {
        '& label': {
            margin: 0,
            '&::after': {
                content: `''`,
                position: 'absolute',
                left: '12px',
                top: '12px',
                width: 'calc(100% - 24px)',
                height: '14px',
                backgroundColor: '#9C9C9C',
                zIndex: -1,
                borderRadius: '50px'
            },
            '& > span': {
                '& > span': {
                    opacity: 0,

                    '& > div': {
                        position: 'relative',
                        borderRadius: '50%',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px -1px 3px 0px rgb(0 0 0 / 12%)',
                        height: '20px',
                        width: '20px',
                        '& svg': {
                            display: 'none'
                        }
                    }
                }
            }
        },
        '& > label:nth-child(1)': {
            '& .Mui-checked': {
                '& > span': {
                    opacity: 1,
                    '& > div': {
                        backgroundColor: theme?.palette?.error?.light
                    }
                },
            }
        },
        '& > label:nth-child(2)': {
            marginLeft: '-9px',
            '& .Mui-checked': {
                '& > span': {
                    opacity: 1,
                    '& > div': {
                        backgroundColor: '#FAFAFA'
                    }
                },
            }
        },
        '& > label:nth-child(3)': {
            marginLeft: '-9px',
            '& .Mui-checked': {
                '& > span': {
                    opacity: 1,
                    '& > div': {
                        backgroundColor: theme?.palette?.success?.light
                    }
                }
            }
        }
	}
}));

const choices = [
    { id: false, value: '' },
    { id: '', value: '' },
    { id: true, value: '' },
 ];

const NullableBooleanSwitch = (props) => {
    const { label, source, resource, isRequired } = props;
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <RadioButtonGroupInput label="" choices={choices} optionText="value" {...props} options={{ className: classes.switch }} />
            <FormLabel className={classes.label}>
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                    className={classes.label}
                />
            </FormLabel>
        </Box>
    );
};

export default NullableBooleanSwitch;
