import React from 'react';
import { List, SearchInput, DateInput, ReferenceInput, AutocompleteInput, NullableBooleanInput, TopToolbar, Link, SelectColumnsButton, useTranslate, useGetIdentity, useListContext } from 'react-admin';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AddIcon from '@mui/icons-material/Add';
import { Chip } from '@mui/material';

import { DefaultListActions } from '../common/views/List';
import IconButton from '../common/buttons/IconButton';
import SplitButton from '../common/buttons/SplitButton';
import TimeEntryRow from './TimeEntryRow';
import ImportButton from './buttons/ImportButton';
import TimerButton from './buttons/TimerButton';
import ExportButton from './buttons/ExportButton';
import ProjectInput from '../projects/inputs/ProjectInput';

const optionText = choice => choice?.name ? choice.name : '';
const userOptionText = choice => choice?.fullname ? choice.fullname : '';


const QuickFilter = ({ label }) => {
    const translate = useTranslate();

    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const getTimeEntryFilters = (account) => {
    return [
        <SearchInput source="q" alwaysOn />,
        <ProjectInput source="project_id" alwaysOn />,
        <ReferenceInput source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={userOptionText} size="small" label="User" />
        </ReferenceInput>,
        <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={optionText} size="small" />
        </ReferenceInput>,
        <DateInput source="start_date" />,
        <DateInput source="end_date" />,
        <NullableBooleanInput source="invoiced" />,
        <QuickFilter source="user_id" label="For me" defaultValue={account?.id} />
    ];
};

const TimeEntryListActions = (props) => {
    const { filterValues } = useListContext();
    const translate = useTranslate();

    return (
        <TopToolbar sx={{ alignItems: 'center' }}>
            <DefaultListActions showCreate={false}>
                <IconButton component={Link} to="/time_entries/stats" icon={<QueryStatsIcon />}>{translate('Stats')}</IconButton>
                <SplitButton>
                    <IconButton component={Link} to="/time_entries/create" icon={<AddIcon />}>{translate('ra.action.create')}</IconButton>
                    <TimerButton />
                    <ImportButton {...props} />
                    <SelectColumnsButton preferenceKey="time_entries.datagrid" />
                </SplitButton>
                <ExportButton filterValues={filterValues} />
            </DefaultListActions>
        </TopToolbar>
    );
};

const TimeEntryList = props => {
    const { data: account } = useGetIdentity();

    return (
        <List filters={getTimeEntryFilters(account)} sort={{ field: 'date', order: 'DESC' }} exporter={false} actions={<TimeEntryListActions />} perPage={50} {...props}>
            <TimeEntryRow />
        </List>
    );
};

export default TimeEntryList;