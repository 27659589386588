import React from 'react';
import { Link } from 'react-admin';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Ul = styled('ul')({
	display: 'flex',
	alignItems: 'stretch',
	padding: 0,
	paddingBottom: '5px'
});

const Li = styled('li')(({ theme }) => ({
	listStyle: 'none',
	display: 'flex',
	alignItems: 'flex-end',

	'& a, & p': {
		lineHeight: '20px'
	},
	'& a': {
		display: 'flex',
		alignItems: 'flex-end',
		textDecoration: 'none',

		'& svg': {
			paddingRight: '5px'
		}
	},

	'&:not(:last-child)::after': {
		content: '"/"',
		padding: '0 5px',
		color: theme?.palette?.text?.primary,
		lineHeight: '20px'
	}
}));

const sxText = {
	display: 'inline',
	fontSize: '14px'
};

const capitalize = (str) => {
	if (!str) {
		return;
	}
	str = str.toString();
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const Breadcrumb = ({ path }) => (
	<Ul>
		{path.map((item) => (
			<Li key={`breadcrumb_${item.title}`}>
				{!item.isActive ? (
					<Link to={item.url} sx={sxText}>{item.icon} {capitalize(item.title)}</Link>
				) : (
					<Typography sx={sxText} color="textPrimary">{capitalize(item.title)}</Typography>
				)}
			</Li>
		))}
	</Ul>
);

export default Breadcrumb;