import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const optionText = choice => choice?.name ? choice.name + ((choice?.customer?.name && choice?.customer?.name !== choice?.name) ? ` (${choice.customer.name})` : '') : '';


const ProjectInput = ({ helperText, onChange, ...props }) => (
    <ReferenceInput source="project_id" reference="projects" sort={{ field: 'name', order: 'ASC' }} {...props}>
        <AutocompleteInput optionText={optionText} helperText={helperText} onChange={onChange} />
    </ReferenceInput>
);

export default ProjectInput;
