import React from 'react';
import { ReferenceField, FunctionField } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';

const ProjectField = (props) => (
    <ReferenceField label="Project" source="project_id" reference="projects" allowEmpty link="show" {...props}>
        <FunctionField
            render={record => (
                <Tooltip title={record?.customer?.name}>
                    <span>{record.name}</span>
                </Tooltip>
            )}
        />
    </ReferenceField>
);

export default ProjectField;
