import React, { useState } from 'react';
import { CardActions, Button, TextField, Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Login from './Login';

import { forgotPassword } from '../../services/api';

const sx = {
	icon: {
		marginRight: theme => theme.spacing(1)
	},
	form: {
		padding: '0 8px 8px 8px'
	},
	field: {
		maxWidth: '100%'
	}
};

const ForgotPasswordPage = () => {
	const [login, setLogin] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		formData.append('login', login);
		const response = await forgotPassword(formData);
		setLoading(false);
		if (response.success) {
			setMessage('An email has been sent');
		}
		else {
			setError(response.message);
		}
	};

	return (
		<Login>
			{message ? (
				<Typography align="center">{message}</Typography>
			) : (
				<form onSubmit={handleSubmit}>
					<Box sx={sx.form}>
						<TextField label="Email" type="text" name="login" value={login} onChange={(e) => setLogin(e.target.value)} helperText={error} error={!!error} size="small" fullWidth sx={sx.field} />
					</Box>
					<CardActions>
						<Button
							variant="contained"
							type="submit"
							color="primary"
							disabled={loading}
							fullWidth
						>
							{loading && (
								<CircularProgress
									sx={sx.icon}
									size={18}
									thickness={2}
								/>
							)}
							Forgot password
						</Button>
					</CardActions>
				</form>
			)}
		</Login>
	);
};

export default ForgotPasswordPage;
