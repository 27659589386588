import React from 'react';
import { List, TopToolbar, ReferenceInput, AutocompleteInput, SearchInput, SortButton, useStore, useTranslate, useGetIdentity } from 'react-admin';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Chip } from '@mui/material';

import { DefaultListActions } from '../../common/views/List';
import StatusInput from '../inputs/StatusInput';
import CategoryInput from '../inputs/CategoryInput';
import ProjectRow from './ProjectRow';
import ProjectGrid from './ProjectGrid';

const buttonGroupSx = { marginBottom: '-2px' };
const inactiveButtonSx = { color: (theme) => theme.palette.action.disabled };

const customerOptionText = choice => choice?.name ? choice.name : '';


const ViewButton = ({ viewGrid, setViewGrid }) => {
    const handleClickList = () => {
        setViewGrid(false);
    };

    const handleClickGrid = () => {
        setViewGrid(true);
    };

    return (
        <ButtonGroup size="small" variant="text" sx={buttonGroupSx}>
            <Button onClick={handleClickList} sx={viewGrid ? inactiveButtonSx : {}}><ViewHeadlineIcon /></Button>
            <Button onClick={handleClickGrid} sx={viewGrid ? {} : inactiveButtonSx}><ViewModuleIcon /></Button>
        </ButtonGroup>
    );
};

const ProjectListActions = (props) => (
    <TopToolbar>
        <DefaultListActions>
            {props.viewGrid && <SortButton fields={['status', 'name', 'customer_id']} />}
            <ViewButton {...props} />
        </DefaultListActions>
    </TopToolbar>
);

const QuickFilter = ({ label }) => {
    const translate = useTranslate();

    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const getProjectFilters = (account) => {
    return [
        <SearchInput source="q" alwaysOn />,
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={customerOptionText} size="small" />
        </ReferenceInput>,
        <StatusInput source="status" alwaysOn />,
        <CategoryInput source="category" />,
        <QuickFilter source="user_id" label="My projects only" defaultValue={account?.id} />
    ];
};

const ProjectList = props => {
    const [viewGrid, setViewGrid] = useStore('projects.list.viewGrid', false);
    const { data: account } = useGetIdentity();

    let customProps = {};
    if (viewGrid) {
        customProps = { component: 'div' };
    }

    return (
        <List {...props} perPage={25} actions={<ProjectListActions viewGrid={viewGrid} setViewGrid={setViewGrid} />} filters={getProjectFilters(account)} {...customProps}>
            {viewGrid ? (
                <ProjectGrid />
            ) : (
                <ProjectRow />
            )}
        </List>
    );
};

export default ProjectList;