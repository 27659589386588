import React from 'react';
import { DatagridConfigurable, TextField, ReferenceField, useRecordContext, useGetIdentity, usePermissions, NumberField, BooleanField, BulkDeleteButton, WrapperField } from 'react-admin';

import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/buttons/RecordSplitButton';
import TooltipTextField from '../common/fields/TootlipTextField';
import BulkInvoicedButton from './buttons/BulkInvoicedButton';
import ProjectField from 'components/projects/fields/ProjectField';

const SplitButton = (props) => {
    const record = useRecordContext();
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const hasPermission = permissions?.includes('is_admin') || (!record?.invoiced && record?.user_id === account?.id);

    if (!hasPermission) {
        return null;
    }

    return (
        <RecordSplitButton
            {...props}
            hasShow={false}
            hasEdit={hasPermission}
            hasDelete={hasPermission}
        />
    );
};

const TimeEntryBulkActionsButtons = () => (
    <>
        <BulkInvoicedButton />
        <BulkDeleteButton mutationMode='pessimistic' />
    </>
);

const TimeEntryRow = ({ children, ...props }) => (
    <DatagridConfigurable bulkActionButtons={<TimeEntryBulkActionsButtons />} rowClick={false} {...props}>
        <TextField source="id" />
        <DateField source="date" />
        <ReferenceField label="User" source="user_id" reference="users" allowEmpty link="show">
            <TextField source="trigram" />
        </ReferenceField>
        <ProjectField label="Project" source="project_id" />
        <TextField source="description" />
        <ReferenceField label="Type" source="type_id" reference="time_entry_types" allowEmpty link="show">
            <TextField source="name" />
        </ReferenceField>
        <NumberField source="pretty_hours" label="Hours" sortBy="hours" />
        <BooleanField source="invoiced" />
        <TooltipTextField source="notes" />
        {children}
        <WrapperField source="actions" label="">
            <SplitButton />
        </WrapperField>
    </DatagridConfigurable>
);

export default TimeEntryRow;