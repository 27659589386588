import React from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Grid from '@mui/material/Grid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';

import DateField from '../common/fields/DateField';
import IconCard from '../common/IconCard';

const NOW_DATE = moment().format('YYYY-MM-DD');

const NextLeaves = ({ data }) => {
    if (data?.length > 0) {
        return (
            <List dense sx={{ padding: 0 }}>
                {data.map(record => (
                    <ListItemButton component={Link} to={`/leaves/${record.id}/show`} key={`next_leaves_${record.id}`}>
                        <ListItemText
                            primary={
                                <>
                                    <DateField source="start_date" record={record} /> {record?.end_date !== record?.start_date && (<>- <DateField source="end_date" record={record} /></>)}
                                </>
                            }
                            secondary={record?.user?.fullname}
                        />
                        <ListItemSecondaryAction>
                            <Box
                                component="span"
                                sx={{
                                    marginRight: '1em',
                                    color: 'text.primary',
                                }}
                            >
                                {record.duration}j
                            </Box>
                        </ListItemSecondaryAction>
                    </ListItemButton>
                ))}
            </List>
        );
    }

    return null;
};

const NextLeavesCard = () => {
    const { data } = useGetList(
        'leaves',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'start_date', order: 'ASC' },
            filter: { status: 'accepted', start_date: NOW_DATE }
        }
    );
    const translate = useTranslate();

    if (data?.length > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard
                    title={translate('Next leaves')}
                    icon={<CalendarMonthIcon />}
                    content={<NextLeaves data={data} />}
                    sx={{ height: '100%' }}
                    linkTo={`/leaves?filter={"status":"accepted","start_date":"${NOW_DATE}"}&sort=start_date&order=ASC`}
                />
            </Grid>
        );
    }

    return null;
};

export default NextLeavesCard;