import React from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput, BooleanInput, ArrayInput, SimpleFormIterator, useGetIdentity, Pagination, useTranslate } from 'react-admin';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';
import { useWatch } from 'react-hook-form';

import Create, { CreateActions } from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';
import CustomList from '../common/views/CustomList';
import HelpButton from './buttons/HelpButton';
import TimerButton from './buttons/TimerButton';
import TimeEntryRow from './TimeEntryRow';
import ProjectInput from '../projects/inputs/ProjectInput';

const optionText = choice => choice?.name ? choice.name : '';

const NOW = moment().format('YYYY-MM-DD');
const defaultValues = { default_date: NOW, time_entries: [{}] };

const formSx = {
    '&& .RaArrayInput-root': {
        minWidth: '100% !important'
    }
};

const simpleFormIteratorSx = {
    '& .RaSimpleFormIterator-line': {
        padding: '15px 0'
    },
    '& .RaSimpleFormIterator-form': {
        gap: '5px'
    },
    '&& .MuiFormControl-root, && .MuiAutocomplete-root': {
        minWidth: '180px !important',
        height: '100%',
        '.MuiInputBase-root': {
            height: '100%',
        },
        '&.ra-input-hours': {
            minWidth: '80px !important',
            width: '80px'
        },
        '&.ra-input-date': {
            minWidth: '140px !important',
            width: '140px'
        },
        '&.ra-input-description': {
            minWidth: '275px !important'
        },
        '&.ra-input-notes': {
            minWidth: '200px !important'
        }
    },
    '& .ra-input-invoiced': {
        justifyContent: 'center',
    }
};

const transform = data => {
    let form = { ...data };
    delete form.default_date;
    return form;
};

const TimeEntryActions = () => (
    <CreateActions>
        <HelpButton />
        <TimerButton />
    </CreateActions>
);

const TimeEntriesArrayInput = () => {
    const defaultDate = useWatch({ name: "default_date" });

    return (
        <ArrayInput source="time_entries" label="">
            <SimpleFormIterator inline sx={simpleFormIteratorSx}>
                <DateInput source="date" helperText={false} defaultValue={defaultDate} />
                <ProjectInput helperText={false} />
                <TextInput source="description" multiline rows="2" helperText={false} />
                <ReferenceInput source="type_id" reference="time_entry_types" sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteInput optionText={optionText} helperText={false} />
                </ReferenceInput>
                <NumberInput source="hours" step={0.25} min={0.25} helperText={false} />
                <TextInput source="notes" multiline rows="2" helperText={false} />
                <BooleanInput source="invoiced" helperText={false} />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const LastTimeEntryList = () => {
    const { data: account } = useGetIdentity();
    const translate = useTranslate();

    return (
        <Grid item xs={12} sx={{ margin: '15px 0' }}>
            <Card>
                <CardHeader title={translate('Last time entries')} />
                <CardContent sx={{ padding: 0 }}>
                    <CustomList
                        resource="time_entries"
                        sort={{ field: 'date', order: 'DESC' }}
                        filter={{ user_id: account?.id }}
                        basePath="/time_entries"
                        filters={null}
                        exporter={false}
                        actions={false}
                        pagination={<Pagination rowsPerPageOptions={[10]} />}
                        perPage={10}
                        empty={false}
                        sx={{ '& .RaList-content': { boxShadow: 'none !important', border: 'none' } }}
                        title=" "
                    >
                        <TimeEntryRow bulkActionButtons={false} />
                    </CustomList>
                </CardContent>
            </Card>
        </Grid>
    );
};

const TimeEntryBulkCreate = (props) => (
    <>
        <Create {...props} resource="time_entries" actions={<TimeEntryActions />} transform={transform} mutationOptions={{ meta: { bulk: true } }}>
            <SimpleForm defaultValues={defaultValues} sx={formSx}>
                <DateInput source="default_date" sx={{ margin: '0 8px' }} />
                <TimeEntriesArrayInput />
            </SimpleForm>
        </Create>
        <LastTimeEntryList />
    </>
);

export default TimeEntryBulkCreate;
