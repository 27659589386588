import React from 'react';
import { TabbedShowLayout, Tab, TextField, UrlField, ReferenceManyField } from 'react-admin';

import Show from '../common/views/Show';
import DateField from '../common/fields/DateField';
import RichTextField from '../common/fields/RichTextField';
import ProjectRow from '../projects/ProjectList/ProjectRow';


const ProjectArrayField = (props) => (
	<ReferenceManyField {...props} reference="projects" target="customer_id">
		<ProjectRow />
	</ReferenceManyField>
);

const CustomerShow = props => (
	<Show {...props}>
		<TabbedShowLayout>
			<Tab label="Summary">
				<TextField source="id" />
				<TextField source="name" />
				<TextField source="short_name" />
				<UrlField source="website" />
				<RichTextField source="description" />
				<DateField source="created_at" />
			</Tab>
			<Tab label="Projects">
				<ProjectArrayField label={false} />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default CustomerShow;