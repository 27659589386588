import React from 'react';
import { useGetList, usePermissions, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

import DateField from '../common/fields/DateField';
import IconCard from '../common/IconCard';

const PendingLeaves = ({ data }) => {
    if (data?.length > 0) {
        return (
            <List dense sx={{ padding: 0 }}>
                {data.map(record => (
                    <ListItemButton component={Link} to={`/leaves/${record.id}/show`} key={`pending_leaves_${record.id}`}>
                        <ListItemText
                            primary={
                                <>
                                    <DateField source="start_date" record={record} /> {record?.end_date !== record?.start_date && (<>- <DateField source="end_date" record={record} /></>)}
                                </>
                            }
                            secondary={record?.user?.fullname}
                        />
                        <ListItemSecondaryAction>
                            <Box
                                component="span"
                                sx={{
                                    marginRight: '1em',
                                    color: 'text.primary',
                                }}
                            >
                                {record.duration}j
                            </Box>
                        </ListItemSecondaryAction>
                    </ListItemButton>
                ))}
            </List>
        );
    }
    return null;
};

const PendingLeavesCard = ({ stats }) => {
    const { data } = useGetList(
        'leaves',
        {
            pagination: { page: 1, perPage: 4 },
            sort: { field: 'start_date', order: 'ASC' },
            filter: { status: 'pending' }
        }
    );
    const { permissions } = usePermissions();
    const translate = useTranslate();

    if (permissions?.includes('is_admin') && data?.length > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard
                    title={translate('Pending leaves')}
                    value={stats?.leaves?.pending}
                    icon={<EditCalendarIcon />}
                    warning={stats?.leaves?.pending > 0}
                    content={<PendingLeaves data={data} />}
                    sx={{ height: '100%' }}
                    linkTo='/leaves?filter={"status": "pending"}'
                />
            </Grid>
        );
    }
    return null;
};

export default PendingLeavesCard;