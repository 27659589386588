// in src/App.js
import React from 'react';
import { Admin, Resource, CustomRoutes, Authenticated } from 'react-admin';
import { Route } from 'react-router';
import { configureStore } from '@reduxjs/toolkit';
import { Provider, useSelector } from 'react-redux';

import Dashboard from './components/Dashboard';
import AppLayout from './components/AppLayout';
import LoginPage from './components/account/LoginPage';
import Account from './components/account/Account';
import ForgotPassword from './components/account/ForgotPassword';
import ResetPassword from './components/account/ResetPassword';
import UsefulLinks from './components/UsefulLinks';
import ReleaseNotes from './components/ReleaseNotes';
import TimeEntryStats from './components/time_entries/TimeEntryStats';
import TimeEntryTimer from './components/time_entries/TimeEntryTimer';
import NotFound from './components/common/errors/NotFound';

import users from './components/users';
import customers from './components/customers';
import projects from './components/projects';
import weeklies from './components/weeklies';
import monthlies from './components/monthlies';
import leaves from './components/leaves';
import time_entries from './components/time_entries';
import time_entry_types from './components/time_entry_types';
import suggestions from './components/suggestions';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import themeReducer from './reducers/theme';

import i18nProvider from './i18n/i18nProvider';
import { lightTheme, darkTheme } from './style/themes';

const CustomAdmin = () => {
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );

    return (
        <Admin
            layout={AppLayout}
            dashboard={Dashboard}
            loginPage={LoginPage}
            dataProvider={dataProvider}
            authProvider={authProvider}
            title="Kernel"
            disableTelemetry
            theme={theme}
            i18nProvider={i18nProvider}
            catchAll={NotFound}
        >
            {permissions => (
                <>
                    <Resource name="users" {...(permissions?.includes('is_admin') ? users : { list: users.list })} />
                    <Resource name="customers" {...customers} />
                    <Resource name="projects" {...projects} />
                    <Resource name="weeklies" {...weeklies} />
                    <Resource name="monthlies" {...(permissions?.includes('is_admin') ? monthlies : { list: monthlies.list, show: monthlies.show })} />
                    <Resource name="leaves" {...leaves} />
                    <Resource name="time_entries" {...time_entries} />
                    <Resource name="time_entry_types" {...time_entry_types} />
                    <Resource name="suggestions" {...suggestions} />
                    <Resource name="redmine/issues" />
                    <Resource name="redmine/projects" />
                    <CustomRoutes>
                        <Route path="/account" element={<Authenticated><Account /></Authenticated>} />
                        <Route path="/useful-links" element={<Authenticated><UsefulLinks /></Authenticated>} />
                        <Route path="/release-notes" element={<Authenticated><ReleaseNotes /></Authenticated>} />
                        <Route path="/time_entries/stats" element={<Authenticated><TimeEntryStats /></Authenticated>} />
                        <Route path="/time_entries/timer" element={<Authenticated><TimeEntryTimer /></Authenticated>} />
                    </CustomRoutes>
                    <CustomRoutes noLayout>
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset_password/:token" element={<ResetPassword />} />
                    </CustomRoutes>
                </>
            )}
        </Admin>
    );
};

const App = () => (
    <Provider store={configureStore({ reducer: { theme: themeReducer } })}>
        <CustomAdmin />
    </Provider>
);

export default App;
