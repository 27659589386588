import React, { useState } from 'react';
import { useTranslate, useGetList } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import IconButton from '../../common/buttons/IconButton';

const HelpButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const { data } = useGetList(
        'time_entry_types',
        {
            pagination: { page: 1, perPage: 300 },
            sort: { field: 'name', order: 'ASC' }
        }
    );

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen} icon={<HelpOutlineIcon />}>{translate('Help')}</IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Help timesheet">
                <DialogTitle>{translate('Help')}</DialogTitle>
                <DialogContent>
                    <Typography>{translate('Minimum duration of 0.25h')}</Typography>
                    <Typography gutterBottom>{translate('0.25h = 15 minutes')}</Typography>

                    <Divider sx={{ margin: '15px 0' }} />

                    {data?.map(type => {
                        if (!type?.description) {
                            return null;
                        }
                        return (
                            <Box key={type.id}>
                                <Typography sx={{ fontWeight: 600 }}>{type.name}</Typography>
                                <Typography gutterBottom sx={{ marginBottom: '15px' }}>{type.description}</Typography>
                            </Box>
                        );
                    })}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default HelpButton;