import React from 'react';
import { useTranslate } from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';

import IconButton from '../../common/buttons/IconButton';

import { API_URL } from '../../../services/settings';

const ExportButton = ({ filterValues }) => {
    const translate = useTranslate();
    const params = new URLSearchParams();
    for (const key in filterValues) {
        params.append(key, filterValues[key]);
    }

    return (
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/time_entries/export.xls?${params.toString()}&auth_token=${localStorage.getItem('token')}`}>{translate('ra.action.export')}</IconButton>
    );
};

export default ExportButton;